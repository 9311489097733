<template>
  <main>
    Klimaneutralität
  </main>
</template>

<script>
export default {
  name: 'PageClimateNeutrality',
}
</script>
